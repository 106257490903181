export const user = {
  greet: "Сәлем, {{name}}!",
  orders_history: "Тапсырыстар тарихы",
  favorite_products: "Таңдаулы тауарлар",
  logout: "Жүйеден шығу",
  about_bonuses: "Бонус жүйесі туралы",
  bonus: "{{value}} Y coin",
  bonus_acc: "жинақталған",
  bonus_used: "қолданылған",
  cashback: "Әр тапсырыстан {{percent}}% кэшбек",
  how_to_bonus: "Жинақтау шарты",
  cashback_lvls: "Кэшбэк деңгейлері",
  current_lvl_text: "Қазіргі деңгей",
  lvl_1_text: "Әрбір төлемнен тұрақты кэшбэк",
  lvl_2_text: "Барлық тапсырыстар үшін, yume.rent арқылы 2 000 000 ₸ астам жұмсаған кезде",
  lvl_3_text: "Барлық тапсырыстар үшін, yume.rent арқылы 6 000 000 ₸ астам жұмсаған кезде",
  question_1: "Бонустарды қалай жинауға болады?",
  question_2: "Бонустарды қалай жұмсауға болады?",
  question_3: "Бонустар қанша уақыт сақталады?",
  question_4: "Кэшбэкті қалай көбейтуге болады?",
  answer_1: "yume.rent сайтында тапсырыс беру арқылы сіз автоматты түрде бонустар аласыз.",
  answer_2: "Тіркелген клиенттер үшін эквайринг арқылы біздің веб-сайтта төлем жасағанда немесе менеджер арқылы жұмсауға болады. Тапсырысыңыздың сомасынан 30%-ға дейін бонустармен төлей аласыз.",
  answer_3: "180 күн ішінде бонустармен ешқандай транзакция жасамаған клиенттерде бонустар күйіп кетеді.",
  answer_4: `
    Біздің адалдық бағдарламамыз аясында, yume.rent сайтындағы сатып алуларыңыздың көлеміне байланысты кэшбэк мөлшерін ұлғайту мүмкіндігін ұсынамыз. Міне, бұл қалай жұмыс істейді:
    <br>“Blue” деңгейі: Әр клиент, жалпы сомасы 50 000 тг және одан жоғары сатып алулар жасағанда, “Blue” деңгейін алады, бұл 3% тұрақты кэшбэк деген сөз.
    <br>“Silver” деңгейіне өту: 6% кэшбэк алу үшін сіздің жалпы шығындарыңыз yume.rent сайтында 1 000 000 ₸-ден асуы тиіс. Бұл соманы жеткізген сәтте сіз автоматты түрде “Silver” деңгейіне көтерілесіз.
    <br>Ең жоғары “Gold” деңгейі: Ең жоғары деңгейге жету үшін, сіздің жалпы сатып алуларыңыз 3 000 000 ₸-ден асып кетуі тиіс. “Gold” деңгейінде сіз 9% кэшбэк аласыз.
    <br>Айта кету керек: Келесі деңгейге өту үшін есептелетін жалпы сома тек сайтта карта арқылы жүргізілген төлемдер мен кеңседе қолма-қол ақшамен төленген төлемдерден тұрады. Бонустармен жүргізілген төлемдер есепке алынбайды. Мысалы, егер сіздің тапсырысыңыздың жалпы құны 10 000 ₸ болса және оның 3 000 ₸-сін бонустармен төлесеңіз, онда сіздің жұмсалған сомаңыз тек 7 000 ₸-ға артады.
  `,
}