export const user = {
  greet: "Привет, {{name}}!",
  orders_history: "История Аренд",
  favorite_products: "Избранные товары",
  logout: "Выйти из аккаунта",
  about_bonuses: "О бонусной системе",
  bonus: "{{value}} Y coin",
  bonus_acc: "накоплено",
  bonus_used: "использовано",
  cashback: "Кэшбек {{percent}}% c каждого заказа",
  how_to_bonus: "Как накопить",
  cashback_lvls: "Уровни кэшбека",
  current_lvl_text: "Текущий уровень",
  lvl_1_text: "Постоянный кэшбек с каждой оплаты",
  lvl_2_text: "На все заказы, когда вы потратите больше 2 000 000 ₸ за все время с yume.rent",
  lvl_3_text: "На все заказы, когда вы потратите больше 6 000 000 ₸ за все время с yume.rent",
  question_1: "Как накопить бонусы?",
  question_2: "Как потратить бонусы?",
  question_3: "Как долго хранятся бонусы?",
  question_4: "Как увеличить кэшбек?",
  answer_1: "Делая заказы на сайте yume.rent вы автоматически получаете бонусы.",
  answer_2: "Для клиентов с личным кабинетом при оплате на сайте с помощью эквайринга и через менеджера при дистанционной оплате. Бонусами вы можете оплачивать до 30% вашего заказа.",
  answer_3: "Бонусы сгорают у клиентов, кто не делал каких-либо транзакций бонусами в течении 180 дней.",
  answer_4: `
    В рамках нашей программы лояльности, мы предлагаем нашим клиентам увеличивать размер кэшбека по мере того, как они совершают покупки на сайте yume.rent. Вот как это работает:
    <br>Уровень “Blue”: Каждому клиенту, совершившему покупки на общую сумму 50 000 тг и более, присваивается уровень “Blue”. Это означает постоянный кэшбек в размере 3%.
    <br>Переход на уровень “Silver”: Чтобы получить кэшбек 6%, необходимо потратить 1 000 000 ₸ и более. Как только вы достигнете этой суммы, ваш уровень автоматически повысится до “Silver”.
    <br>Максимальный уровень “Gold”: Для достижения уровня “Gold” с кэшбеком в 9%, вам нужно потратить более 3 000 000 ₸.
    <br>Важно отметить: Сумма для перехода на следующий уровень учитывает только те деньги, которые вы платите картой на нашем сайте или наличными в офисе. Оплата бонусами в расчет не идет. Например, если общая стоимость вашего заказа составляет 10 000 ₸, и из них 3 000 ₸ оплачены бонусами, то в вашу сумму покупок зачисляется только 7 000 ₸.
  `,
}
