import {useContext, useEffect, useState} from "react"
import AnimateHeight from "react-animate-height"
import {useTranslation} from "react-i18next"
import {localeString} from "core/utils/localeString"
import "./faq.scss"
import {ApplicationContext} from "../../global-context"

function FaqAnswer(props: {question: string; answer: string}) {
  const [height, setHeight] = useState<0 | "auto">(0)
  const {t} = useTranslation()
  const {isMobile, setContentHeaderConfig} = useContext(ApplicationContext)

  const onToggle = () => setHeight(height === 0 ? "auto" : 0)

  useEffect(() => {
    if (isMobile) setContentHeaderConfig({hidden: true})
    return () => setContentHeaderConfig({hidden: false})
  }, [])

  return (
    <div className="question">
      <div className="question-body pointer" onClick={onToggle}>
        <div className="question-body-text" dangerouslySetInnerHTML={{__html: t(props.question)}}></div>
      </div>
      <AnimateHeight duration={250} height={height} className="question-answer">
        <div dangerouslySetInnerHTML={{__html: t(props.answer)}}></div>
      </AnimateHeight>
    </div>
  )
}

interface Question {
  question_kk: string
  question_ru: string
  answer_kk: string
  answer_ru: string
}

export function FaqList() {
  const {t} = useTranslation()

  const qa: Question[] = [
    {
      question_kk: "Орналасқан жерлеріңіз қайда?",
      question_ru: "Где вы находитесь?",
      answer_kk: `<div style="font-size:14px;">Біздің кеңсенің мекенжайы:&nbsp;<br>Yume.rent<br><a href="https://go.2gis.com/5qkumn" target="_blank" style="color: rgb(0, 0, 0);">Желтоқсан көшесі,&nbsp;168а</a>, Алматы<br><br><a href="https://go.2gis.com/5qkumn" target="_blank"><strong>2GIS-те көру</strong></a></div>`,
      answer_ru: `<div style="font-size:14px;">Наш офис находится по адресу:&nbsp;<br>Yume.rent<br><a href="https://go.2gis.com/5qkumn" target="_blank" style="color: rgb(0, 0, 0);">улица Желтоксан,&nbsp;168а</a>, Алматы<br><br><a href="https://go.2gis.com/5qkumn" target="_blank"><strong>Посмотреть в 2GIS</strong></a></div>`,
    },
    {
      question_kk: "Қандай төлем операциялары бар?",
      question_ru: "Какие варианты оплаты есть?",
      answer_kk: `Тіркелмеген клиенттерімізге жабдықты жалға алу үшін Kaspi QR, сайттан “Төлем” функциясы арқылы, ал жеке кабинеті бар клиенттердің Kaspi QR немесе эквайринг арқылы төлеіге мүмкіндігі бар, офисымызда кез-келген банк карталары және заңды тұлғалар үшін төлем шоттары арқылы төлей аласыз. Біз аударымдар қабылдамаймыз.
      <br>Егер сіз жалға алушы болсаңыз, бірақ төлемдерді басқа тұлға жүзеге асырса, Kaspi Pay-ге телефон нөмірі арқылы қашықтан шот ұсына аламыз немесе сайттағы "төлем" функциясы арқылы төлемді қашықтан да жасай алады.`,
      answer_ru: `Оплатить аренду оборудования можно с помощью: функции “Оплата” на главной странице для незарегистрированных пользователей, через Kaspi QR и Эквайринг для зарегистрированных клиентов в личном кабинете, с помощью любых банковских карт в нашем офисе и счета на оплату для юридических лиц. Мы не принимаем переводы.
      <br>В случае если вы арендатор, но выплаты осуществляет другой человек, мы можем удаленно выставить счет в Kaspi Pay по номеру телефона или можете произвести оплату с помощью функции “Оплата” на главной странице сайта либо в личном кабинете.`,
    },
    {
      question_kk: "Жалдау қалай жүзеге асырылады?",
      question_ru: "Как оформляется аренда?",
      answer_kk: `<div style="font-size:14px;">Егер сіз бізден жабдықты бірінші рет жалға алып жатсаңыз, біз сізбен шарт жасасуымыз керек. Жабдықты жалға алу шарты бір рет жасалады және бір жылға жарамды. Сізден ол үшін жеке куәлігіңіз бен телефон нөміріңіз керек. Келесі жалға алулар тек қол қойылған қабылдау актісімен жүзеге асырылады. <br><br>* егер сіз басқа елдің азаматы болсаңыз, сізден ЖСН және тұрғылықты жері бойынша уақытша тіркеу талап етіледі</div>`,
      answer_ru: `<div style="font-size:14px;">Если вы впервые берете у нас оборудование в аренду, то нам предстоит заключить с вами договор. Договор аренды оборудования подписывается один раз и действителен один год. От вас потребуется удостоверение личности и номер телефона. Последующие аренды осуществляются только с подписанным актом приема-передачи. <br><br>* в случае, если вы являетесь гражданином другой страны от вас потребуется ИИН и временная регистрация по месту жительства</div>`,
    },
    {
      question_kk: "Жабдықты қайдан көруге және брондауға болады?",
      question_ru: "А где можно посмотреть технику и как ее забронировать?",
      answer_kk: `<div style="font-size:14px;">Жабдықты жалға алу туралы өтінішті бізге кез келген мессенджерде тікелей жазу немесе веб-сайтта себетті жинау арқылы қалдыра аласыз. Менеджерлер сіздің өтінішіңізді қабылдайды және жалдау шартын растау үшін жақын арада сізбен байланысатын болады.<br><br>Сайтта сіз жалға алуға болатын жабдықтың толық тізімін, сипаттамасын, жалға алу ережелерін, жалдау ақысын қашықтан төлей функциясын, байланыс нөмірі мен мекенжайды табасыз, сондай-ақ барлық акциялар мен хабарландырулардан хабардар болып, біздің қауымдастықтың қосылып, жиі қойылатын сұрақтарға жауаптарды көре аласыз.</div>`,
      answer_ru: `<div style="font-size:14px;">Оставить заявку на аренду оборудования вы можете написав нам напрямую в любой из мессенджеров или собрать корзину на нашем сайте. Менеджеры примут вашу заявку и в ближайшее время свяжуться с вами для подтверждения аренды.<br><br>На сайте вы можете посмотреть весь список оборудования доступного для аренды, полное описание техники, правила аренды, оплатить аренду удаленно, быть в курсе всех акций и объявлений, стать часть нашего комьюнити, узнать контакты и адрес а также увидеть ответы на часто задаваемые вопросы.</div>`,
    },
    {
      question_kk: "Жалдау шарттары қандай?",
      question_ru: "Какие условия аренды?",
      answer_kk: `<div style="font-size:14px;">Жалға алу мерзімі шартқа қол қойып, жабдықты тексергеннен кейін басталады. Жалдау ұзақтығы - 24 сағат. Сондай-ақ, тек механикпен 12 сағатқа жалға берілетін жабдықтар бар. Осы уақыттан кейін жабдықтар кеңсеге қайтарылуы керек. Жабдық уақтылы қайтарылмаған жағдайда, техник сізден жалға алғаны үшін жалпы сома көлемінде айыппұл өндіруге құқылы.</div>`,
      answer_ru: `<div style="font-size:14px;">После заключения договора и проверки оборудования - начинается время аренды. Длительность аренды 24 часа. Также, есть позиции которые сдаются только с механиком на 12 часов. По истечению этого времени технику нужно вернуть в офис. При несвоевременном возврате оборудования, техник имеет право взять с вас штраф в размере общей суммы за аренду.</div>`,
    },
  ]

  return (
    <>
      <div className="faq-header">{t("faq.header")}</div>
      {qa.map((question, key) => (
        <FaqAnswer
          key={key}
          question={t(localeString(question, "question"))}
          answer={t(localeString(question, "answer"))}
        ></FaqAnswer>
      ))}
      {[0, 1].map((key) => (
        <FaqAnswer
          question={t(`faq.list.${key}.0`)}
          answer={t(`faq.list.${key}.1`)}
        ></FaqAnswer>
      ))}
      {[1, 2, 3, 4].map((key) => (
        <FaqAnswer
          question={t(`user.question_${key}`)}
          answer={t(`user.answer_${key}`)}
        ></FaqAnswer>
      ))}
    </>
  )
}
