import {BreadcrumbItemType, BreadcrumbSeparatorType} from "antd/es/breadcrumb/Breadcrumb"
import {ProductItem} from "components/product-item"
import {useStore} from "core/store/storeHooks"
import {localeString} from "core/utils/localeString"
import useOnScreen from "core/utils/onScreen"
import {ApplicationContext} from "global-context"
import {useContext, useEffect, useRef} from "react"
import {useTranslation} from "react-i18next"
import {useLocation, useNavigate, useOutletContext, useParams} from "react-router-dom"
import {BreadcrumbComponent} from "../../components/breadcrumb"
import {ProductListFilter} from "./filter"
import {ProductContext} from "layouts/products"
import "./styles.scss"

export function ProductList() {
  const {slug} = useParams()
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const {isMobile, getCategory} = useContext(ApplicationContext)
  const {loading} = useStore(({app}) => app)
  const elementRef = useRef<HTMLDivElement>(null)
  const isOnScreen = useOnScreen(elementRef)

  const {products, params, reset, setPos} = useOutletContext<ProductContext>()
  const list = products

  const onLoadMore = () => reset({...params, page: list.page + 1, category__slug: slug})

  const breadcrumbConfig: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = slug && [
    {
      title: "Главная",
      onClick: () => navigate("/")
    },
    {
      onClick: () => navigate(`/${slug}`),
      title: t(localeString(getCategory(slug).extra, "name")),
    },
  ]

  useEffect(() => {
    if (!isMobile) return
    if (!isOnScreen) return
    if (!list.next) return
    onLoadMore()
  }, [isMobile, isOnScreen])

  return (
    <>
      <BreadcrumbComponent
        backPath='/'
        items={slug ? breadcrumbConfig : []}
        header={slug ? t(localeString(getCategory(slug).extra, "name")) : t("main.all_products")}
        contentRight={t("product.list_count", {count: list.count})}
        navigatable={slug ? true : false}
      />

      <ProductListFilter />

      <div className="product-list-holder animation-opacity">
        {list.results.map((product, index) => (
          <ProductItem
            key={`${product.type}_${product.id}_${index}`}
            product={product}
            onNavigate={() => {
              const category = getCategory(product.category)
              if (category) {
                const element = document.getElementById(`${product.type}_${product.id}`)
                const elementTop = element.offsetTop || 0
                const divTop = document.getElementById("content-holder").offsetTop
                const elementRelativeTop = elementTop - divTop
                setPos(() => ({top: elementRelativeTop - 120, pathname: location.pathname}))

                navigate(`/${category.slug}/${product.slug}`)
              }
            }}
          />
        ))}
      </div>

      {!loading && !!list.next && !isMobile && (
        <div className="d-flex justify-content-center mt-4">
          <button
            onClick={onLoadMore}
            style={{borderRadius: "24px"}}
            className="btn btn-gray-100 d-flex gap-2 align-items-center p-3 py-2 font-13 weight-500"
          >
            <span>{t('product.load_more')}</span>
            <span className="yume yume-chevron-bottom" />
          </button>
        </div>
      )}

      {!loading && list.results.length === 0 && (
        <div className="d-flex justify-content-center p-3 animation-opacity">
          {t("product.empty")}
        </div>
      )}

      <div ref={elementRef}></div>
    </>
  )
}
