import {useFormContext} from "react-hook-form"
import {OrderForm} from "../index"
import clsx from "clsx"
import {useTranslation} from "react-i18next"
import {useContext} from "react"
import {ApplicationContext} from "global-context"

export const BasketFormDetails = () => {
  const {setValue, watch} = useFormContext<OrderForm>()
  const {profile} = useContext(ApplicationContext)
  const {t} = useTranslation()
  const isDelivery = watch('delivery')

  const onPickup = () => setValue('delivery', false)
  const onDelivery = () => setValue('delivery', profile && profile.verified)

  return (
    <div className="details mb-3">
      <span className="font-13 color-gray-400 mb-2">{t("basket.details")}</span>
      <div className="d-flex gap-2">
        <div onClick={onPickup} className={clsx("details-item", {"active": !isDelivery})}>
          <div className="details-item-status"></div>
          <span className="font-13 weight-500">{t("basket.delivery.pickup")}</span>
        </div>
        <div onClick={onDelivery} className={clsx("details-item flex-column gap-1 align-items-start", {"active": isDelivery})}>
          <div className="d-flex gap-2 align-items-center">
            <div className="details-item-status"></div>
            <span className="font-13 weight-500">{t("basket.delivery.delivery")}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
