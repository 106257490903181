import {loyaltyColor} from "components/coin"
import {useGlobalModalContext} from "components/modal/modal-context"
import {SwitcherComponent, SwitcherConfig} from "components/switch"
import {toPrice} from "core/utils/price"
import {ApplicationContext} from "global-context"
import {useContext} from "react"
import {useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {store} from "core/store/store"
import {setLoading} from "core/store/app"
import {BonusSubscriptionFormSuccess} from "./success"

const prices = {
    'Gold_0': 99900,
    'Silver_0': 59900,
    'Gold_1': 179000,
    'Silver_1': 109900,
}

function BonusFormSection({index, extra = {}, gold = false}: {index: number, extra?: any, gold?: boolean}) {
    const {t} = useTranslation()

    return <div className={`bonus-form-card-section ${gold ? 'gold' : 'silver'}`}>
        <span className="yume yume-bonus color-primary font-24" />
        <div className="font-14" dangerouslySetInnerHTML={{__html: t(`bonus.subscription.sections.${index}`, extra)}}></div>
    </div>
}

export default function BonusSubscriptionForm() {
    const {t} = useTranslation()
    const {profile, isMobile} = useContext(ApplicationContext)
    const durations = [t('bonus.subscription.durations.0'), t('bonus.subscription.durations.1')]
    const {showModal} = useGlobalModalContext()

    const form = useForm({
        defaultValues: {
            name: profile ? `${profile.first_name} ${profile.last_name}` : undefined,
            phone: profile ? profile.phone : '',
            duration_index: 0,
            duration: durations[0],
            subscription: "Silver"
        }
    })
    const {watch, handleSubmit, setValue} = form

    const index = watch('duration_index')
    const subscription = watch('subscription')

    const switcherConfig: SwitcherConfig[] = durations.map((duration, i) => ({
        key: duration,
        text: duration,
        onClick: () => {
            setValue('duration', duration)
            setValue('duration_index', i)
        },
        className: "w-100"
    }))

    const onGold = () => setValue('subscription', 'Gold')
    const onSilver = () => setValue('subscription', 'Silver')

    const onSubmit = handleSubmit(async (payload) => {
        const formData = new FormData()
        formData.append('entry.112168448', payload.phone)
        formData.append('entry.253891161', payload.name)
        formData.append('entry.417447421', payload.subscription)
        formData.append('entry.434185655', payload.duration)

        store.dispatch(setLoading(true))
        await fetch(
            "https://docs.google.com/forms/u/0/d/e/1FAIpQLSda0J9Xp8zPfU2DxwvRyV_I36GlS1TKNQAQOCn7kR1ym9eemw/formResponse",
            {
                method: "POST",
                mode: "no-cors",
                headers: {"Content-Type": "application/json"},
                body: formData,
            }
        )
        store.dispatch(setLoading(false))

        showModal({
            component: <BonusSubscriptionFormSuccess />,
            className: isMobile && "modal-bottom",
            centered: true
        })
    })

    return <div className="bonus-form">
        <h1 className="font-22 weight-700 mb-3">{t("bonus.subscription.label")}</h1>
        <SwitcherComponent className="bg-light w-100 mb-3" active={watch('duration')} items={switcherConfig} />

        <div className="bonus-form-card pointer mb-3" onClick={onGold}>
            <div className="btn btn-color-white weight-500 gap-1 mb-2" style={{background: loyaltyColor[9], width: 'max-content', padding: '6px 8px', borderRadius: 8}}>
                <span className="yume yume-bonus"></span>
                <span>{t('bonus.subscription.recommended')}</span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex gap-1 flex-column">
                    <div className="font-20 weight-600">Gold</div>
                    <div className="font-14 color-gray-400">{toPrice(prices[`Gold_${index}`])}</div>
                </div>
                <div className={`checker ${subscription === 'Gold' ? 'active' : ''}`}></div>
            </div>
            <BonusFormSection index={0} extra={{percent: 9}} gold />
            <BonusFormSection index={1} extra={{price: toPrice(400000)}} gold />
            <BonusFormSection index={2} extra={{price: toPrice(3000)}} gold />
            <BonusFormSection index={4} gold />
        </div>

        <div className="bonus-form-card pointer mb-3" onClick={onSilver}>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex gap-1 flex-column">
                    <div className="font-20 weight-600">Silver</div>
                    <div className="font-14 color-gray-400">{toPrice(prices[`Silver_${index}`])}</div>
                </div>
                <div className={`checker ${subscription === 'Silver' ? 'active' : ''}`}></div>
            </div>
            <BonusFormSection index={0} extra={{percent: 6}} />
            <BonusFormSection index={1} extra={{price: toPrice(300000)}} />
            <BonusFormSection index={2} extra={{price: toPrice(12000)}} />
            <BonusFormSection index={3} />
        </div>

        <div className="d-flex justify-content-end">
            <button className="btn btn-primary align-items-center gap-1" onClick={onSubmit}>
                <span className="weight-500">{t('bonus.subscription.submit')}</span>
                <span className="yume yume-right font-16" />
            </button>
        </div>

    </div>
}