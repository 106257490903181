import {useEffect, useContext} from "react"
import {ApplicationContext} from "global-context"
import {useTranslation} from "react-i18next"
import {BonusPromoList} from "./bonus-promo/list"
import {BonusAmount} from "./bonus-amount"
import {BonusFAQ} from "./bonus-faq"
import {BonusSubscription} from "./bonus-subscription"
import {useGlobalModalContext} from "components/modal/modal-context"
import {BonusHistory} from "./bonus-history"
import {useNavigate} from "react-router"
import BonusSubscriptionForm from "./form"
import "./styles.scss"

export function AboutBonusesPage() {
  const {t} = useTranslation()
  const {setContentHeaderConfig, isMobile, profile, setAuthModal} = useContext(ApplicationContext)
  const {showModal} = useGlobalModalContext()

  const onHistory = () => {
    showModal({
      component: <BonusHistory />,
      className: isMobile && "modal-bottom",
      centered: true
    })
  }

  const onSubscription = () => {
    if (!profile) {
      setAuthModal('guest')
      return
    }
    showModal({
      component: <BonusSubscriptionForm />,
      className: isMobile && "modal-bottom",
      centered: true,
      // size: 'lg'
    })
  }

  const navigate = useNavigate()
  const onBack = () => navigate(-1)

  useEffect(() => {
    setContentHeaderConfig({
      content: <div className="content-header-default">
        <div className="yume yume-left pointer" onClick={onBack}></div>
        <div className="d-flex justify-content-between align-items-center gap-3">
          <span className="nowrap">{t("user.about_bonuses")}</span>
          <div className="btn btn-primary p-2 ml-auto font-14 weight-500 pointer" onClick={onHistory}>{t("bonus.history.label")}</div>
        </div>
      </div>
    })
    return () => setContentHeaderConfig({hidden: false})
  }, [])

  return <div className="bonus-wrapper">
    <div className="bonus-wrapper-left">
      <BonusAmount />
      {/* <BonusSubscription onSubscription={onSubscription} /> */}
      <BonusFAQ />
    </div>
    <div className="bonus-wrapper-right">
      <BonusPromoList onSubscription={onSubscription} />
      <BonusFAQ />
    </div>
  </div>
}
